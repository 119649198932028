import { WithFields } from "@/api";
import { AlertReviewTask, Cdec, CompanyInfo } from "@/api/alarm";
import { useResizeObserver } from "@mb-pro-ui/components";
import { useGetAll, useGetOne, useUpdate } from "@mb-pro-ui/utils";
import type { JsonapiError } from "@mb-pro-ui/utils/jsonapi/types";
import TimeIcon from "@mui/icons-material/AccessTime";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CallIcon from "@mui/icons-material/Call";
import ClearIcon from "@mui/icons-material/Clear";
import DescriptionIcon from "@mui/icons-material/Description";
import DoneIcon from "@mui/icons-material/Done";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ReplayIcon from "@mui/icons-material/Replay";
import SmsIcon from "@mui/icons-material/Sms";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import DOMPurify from "dompurify";
import qrcode from "qrcode-generator";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { ExtractRouteParams, useRouteMatch } from "react-router";
import { JsonApiErrorSnackbar } from "../../hooks/useErrorHandler";
import useMe from "../../hooks/useMe";
import { ReactComponent as InterventionsMenuIcon } from "../../icons/InterventionsMenu.svg";
import { ReactComponent as AlertReviewTasksIcon } from "../../icons/customer/AlertReviewTasks.svg";
import useTimestampFormat from "../../locales/useTimestampFormat";
import EmailButton from "../email";
import CdecModal from "../operator/cdec/CdecModal";
import { useCdec } from "../operator/cdec/useCdec";
import { SnackbarState } from "../settings/types";
import { Snackbar } from "../settings/utils";
import BootstrapTooltip from "../utils/BootstrapTooltip";
import {
  StyledIconButton,
  StyledTooltip,
} from "../utils/StyledHeaderComponents";
import Widget from "../utils/Widget";
import { useAcknowledge } from "./AcknowledgeContext";
import { getDateText } from "./EventsWidget";
import InterventionCloseDialog from "./InterventionCloseDialog";
import ManualSmsModal from "./ManualSmsModal";
import { ActionGroup, Intervention, Subtask, TaskGroup } from "./types";

export interface ExtendedAlertReviewTask extends AlertReviewTask {
  "created-at": string;
  "completed-at": string | null;
  result: boolean | null;
  details: { reason?: string } | null;
  cdec: Cdec;
}

const getProtocol = (code: string | null) => {
  switch (code) {
    case "C":
      return "tel:";
    case "E":
      return "mailto:";
    case "S":
      return "sms:";
    default:
      return "";
  }
};

function buildHref(protocol: string, address: string | null, body: string) {
  return protocol && address
    ? `${protocol}${address}${body ? `?&body=${encodeURIComponent(body)}` : ""}`
    : null;
}

export const groupTasksByCdecId = (
  tasks: ExtendedAlertReviewTask[] | null | undefined,
): { [key: string]: ExtendedAlertReviewTask[] } => {
  if (!Array.isArray(tasks)) {
    return {};
  }

  return tasks.reduce(
    (groups, task) => {
      const cdecId = task.cdec?.id;
      if (cdecId) {
        if (!groups[cdecId]) {
          groups[cdecId] = [];
        }
        groups[cdecId].push(task);
      }
      return groups;
    },
    {} as { [key: string]: ExtendedAlertReviewTask[] },
  );
};

type SMSCdec = WithFields<Cdec, { cdec: ["arrived", "localized-description"] }>;

function renderQrCode(
  content: string,
  {
    cellSize = 2,
    backgroundColor = "transparent",
    foregroundColor = "black",
  }: { cellSize?: number; backgroundColor?: string; foregroundColor?: string },
) {
  const qr = qrcode(0, "L");
  qr.addData(content);
  qr.make();

  const moduleCount = qr.getModuleCount();
  const ctx = document.createElement("canvas").getContext("2d");
  if (!ctx) {
    return null;
  }

  ctx.canvas.width = cellSize * moduleCount;
  ctx.canvas.height = cellSize * moduleCount;

  if (backgroundColor !== "transparent") {
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  ctx.fillStyle = foregroundColor;
  for (var r = 0; r < moduleCount; r += 1) {
    for (var c = 0; c < moduleCount; c += 1) {
      if (qr.isDark(r, c)) {
        ctx.fillRect(c * cellSize, r * cellSize, cellSize, cellSize);
      }
    }
  }

  return ctx.canvas.toDataURL();
}

function useSMSBody(
  enabled: boolean,
  account?: string,
  company?: string | null,
  cdec?: SMSCdec[],
) {
  const { formatTimestamp: formatTime } = useTimestampFormat("time");

  if (!enabled) {
    return "";
  }

  account = account ? `${account}: ` : "";
  company = company ? `|${company}` : "";

  const events =
    cdec?.map(
      (it) => `${formatTime(it.arrived)} ${it["localized-description"]}`,
    ) ?? [];

  return account + events.join("|") + company;
}

function normalizePossibleRichText(text?: string | null) {
  if (!text) {
    return "";
  }
  if (text.indexOf("<") === -1) {
    if (text.indexOf("\n") === -1) {
      return text;
    }
    return text
      .split("\n")
      .map((line) => {
        line = line.trim();
        return line ? `<span>${line}</span><br>` : "<br>";
      })
      .join("\n");
  }
  return DOMPurify.sanitize(text);
}

const StyledImg = styled("img")({});

const StQrCode = ({
  dataUrl,
  smsBody,
}: {
  dataUrl: string;
  smsBody: string | null;
}) =>
  smsBody ? (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="caption" color="primary">
        {smsBody}
      </Typography>
      <StyledImg src={dataUrl} alt="QR Code" sx={{ display: "block" }} />
    </Box>
  ) : (
    <StyledImg src={dataUrl} alt="QR Code" sx={{ display: "block" }} />
  );

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "#000000",
    borderRadius: "8px",
    padding: "15px",
    backgroundColor: "#e4e4e4fc",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#e4e4e4fc",
  },
}));

const LimitedHeightContent = ({
  content,
  width,
  maxWidth,
}: {
  content: string;
  width?: number | string | undefined;
  maxWidth?: string;
}) => {
  const [isMultiline, _setIsMultiline] = useState(true);
  const target = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const { current } = target;
    if (!current) {
      return;
    }

    const { scrollHeight } = current;
    const { height = Infinity } = current.getClientRects()[0] ?? {};

    _setIsMultiline(scrollHeight > height);
  }, [target]);

  useResizeObserver(target, (entry) => {
    const { scrollHeight } = entry.target;
    _setIsMultiline(scrollHeight > entry.contentRect.height);
  });

  const visibleContent = (
    <Typography
      component="div"
      variant="body2"
      textOverflow="ellipsis"
      sx={{
        "& > p": { margin: 0 },
        maxHeight: "calc(1.43em * 2)",
        overflow: "hidden",
        marginTop: 0.5,
      }}
      ref={target}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );

  const fullContent = (
    <Typography
      component="div"
      variant="body2"
      textOverflow="ellipsis"
      sx={{ "& > p": { margin: 0 } }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
  return isMultiline ? (
    <HtmlTooltip title={fullContent} placement="left" arrow>
      <Box
        sx={{
          display: "flex",
          backgroundColor: `rgba(0, 0, 0, 0.1)`,
          width: width,
          maxWidth: maxWidth,
          p: 0.5,
          position: "relative",
          borderRadius: "10px",
          mt: 0.5,
        }}
      >
        {visibleContent}
        <MoreHorizIcon
          sx={{
            alignSelf: "flex-end",
            ml: 0.5,
          }}
        />
      </Box>
    </HtmlTooltip>
  ) : (
    visibleContent
  );
};

export const normalizePhoneNumber = (phoneNumber: string) =>
  phoneNumber.replace(/[^0-9+]/g, "");

export const useNormalizedRichText = (text?: string | null) =>
  useMemo(() => normalizePossibleRichText(text), [text]);

export const StyledLink = styled("a")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.body2.fontWeight,
}));

const CustomResultWidget = ({
  valueFromAPI,
  initialValue,
  setCustomResults,
  st,
  isTaskWithoutNotification,
  description,
  isLoading,
  isRequestError,
}: {
  valueFromAPI: string[];
  initialValue: string | null;
  setCustomResults: (id: string, customResults: string) => void;
  st: Subtask;
  isTaskWithoutNotification: boolean;
  description: string;
  isLoading: boolean;
  isRequestError: boolean;
}) => {
  const { formatMessage } = useIntl();
  const [selectedValue, setSelectedValue] = useState<string | null>(
    initialValue,
  );
  const [inputValue, setInputValue] = useState<string>(initialValue || "");
  const [isUpdating, setIsUpdating] = useState<boolean>(
    initialValue === null || initialValue === "",
  );
  const [err, setErr] = useState<boolean>(isRequestError);

  const handleSubmit = () => {
    const valueToSubmit = inputValue.trim() !== "" ? inputValue : "";

    if (err && valueToSubmit !== "") {
      setErr(false);
    }

    setCustomResults(st.id, valueToSubmit);
    setSelectedValue(valueToSubmit);

    if (err) {
      setIsUpdating(false);
    }
  };

  const handleUndoClick = () => {
    if (initialValue) {
      setCustomResults(st.id, "");
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
        {isUpdating ? (
          <Box>
            <Typography
              sx={{
                fontSize: "0.875rem",
                fontWeight: 500,
                display: isTaskWithoutNotification ? "block" : "none",
                "& p": { m: 0 },
              }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                mt: 2,
              }}
            >
              <Autocomplete
                value={selectedValue || undefined}
                inputValue={inputValue}
                options={valueFromAPI}
                freeSolo
                disableClearable
                onInputChange={(_, newInputValue) => {
                  setInputValue(newInputValue);
                  if (!newInputValue.trim()) {
                    setSelectedValue(null);
                    setIsUpdating(true);
                  }
                }}
                onChange={(_, newSelectedValue) => {
                  setSelectedValue(newSelectedValue);
                  setInputValue(newSelectedValue || "");
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    if (!document.querySelector(".MuiAutocomplete-popper")) {
                      event.preventDefault();
                      if (inputValue.trim()) {
                        handleSubmit();
                      }
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={formatMessage({ defaultMessage: "Results" })}
                    sx={{ margin: 0 }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {inputValue && (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setInputValue("");
                                  setSelectedValue(null);
                                }}
                              >
                                <ClearIcon sx={{ fontSize: "15px" }} />
                              </IconButton>
                            </InputAdornment>
                          )}
                          <InputAdornment position="end">
                            <ArrowDropDownIcon />
                          </InputAdornment>
                        </>
                      ),
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "0.875rem",
                        color: "#515151",
                      },
                    }}
                  />
                )}
                fullWidth
                disabled={isLoading}
              />
              <StyledTooltip
                title={formatMessage({
                  defaultMessage: "Send",
                })}
              >
                <span>
                  <StyledIconButton
                    sx={{ p: 0, ml: "12px" }}
                    onClick={handleSubmit}
                    disabled={inputValue === ""}
                  >
                    <SvgIcon
                      sx={{
                        color: (theme) =>
                          inputValue !== ""
                            ? theme.palette.primary.main
                            : theme.palette.grey[500],
                      }}
                      viewBox="0 0 45 55"
                      component={InterventionsMenuIcon}
                    />
                  </StyledIconButton>
                </span>
              </StyledTooltip>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {selectedValue && (
              <>
                <Typography
                  sx={{
                    display: isTaskWithoutNotification ? "block" : "none",
                    fontSize: "0.875rem",
                    fontWeight: "500",
                    color: st.status
                      ? (theme) => theme.palette.grey[500]
                      : (theme) => theme.palette.common.black[500],
                    textDecoration: st.status ? "line-through" : "none",
                    "& p": { m: 0 },
                  }}
                  dangerouslySetInnerHTML={{ __html: description }}
                ></Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 1.5,
                  }}
                >
                  <Typography sx={{ fontSize: "0.875rem" }}>
                    {selectedValue}
                  </Typography>
                  <StyledTooltip
                    title={formatMessage({ defaultMessage: "Undo" })}
                  >
                    <StyledIconButton
                      sx={{ p: 0, ml: "auto" }}
                      onClick={handleUndoClick}
                      disabled={!initialValue}
                    >
                      <ReplayIcon
                        sx={{
                          ml: "12px",
                          color: (theme) =>
                            initialValue
                              ? theme.palette.warning.main
                              : theme.palette.divider,
                        }}
                      />
                    </StyledIconButton>
                  </StyledTooltip>
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const STListItem = ({
  it,
  st,
  ag,
  cmp,
  readonly,
  setSubtaskStatus,
  setCustomResults,
  separator,
  isLoading,
  isRequestError,
}: {
  it?: Intervention;
  st: Subtask;
  ag: ActionGroup | null;
  cmp: CompanyInfo | null;
  readonly?: boolean;
  setSubtaskStatus: (id: string, status: boolean) => void;
  setCustomResults: (id: string, customResults: string) => void;
  separator?: boolean;
  isLoading: boolean;
  sx?: SxProps;
  isRequestError: boolean;
}) => {
  const protocol = getProtocol(st.code);
  const isSMS = protocol === "sms:";

  const { data: smsCdecData } = useGetAll<SMSCdec>("alarm/cdec", {
    fields: {
      cdec: ["arrived", "localized-description"],
    },
    filter: {
      intervention: { eq: it?.id },
      "category-alert": { is: "true" },
      "event-category": { in: ag?.rules?.map((rule) => rule.category) },
    },
    sort: ["id"],
    page: {
      limit: 10,
    },
    enabled: isSMS && !!it,
  });

  const { data: user } = useMe();
  const { formatMessage } = useIntl();
  const [manualSmsModalOpen, setManualSmsModalOpen] = useState(false);
  const [snackbarState, setSnackbarState] = useState<
    SnackbarState | undefined
  >();
  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };
  const [initialValue, setInitialValue] = useState<string | null>(null);

  const customerAccount = it?.customer?.account;
  const companyName = cmp?.name;
  const smsBody = useSMSBody(isSMS, customerAccount, companyName, smsCdecData);

  const cleanedAddress =
    protocol === "tel:" || protocol === "sms:"
      ? normalizePhoneNumber(st.address || "")
      : st.address;

  const href = buildHref(protocol, cleanedAddress, smsBody);
  const dataUrl = useMemo(
    () => (href ? renderQrCode(href, { cellSize: 4 }) : null),
    [href],
  );

  const description = `
  <div style="display: flex; align-items: center;">
    <span style="font-variant: small-caps; font-size: 0.9rem">${useNormalizedRichText(st.description)}</span>
    ${st["custom-options"]?.required ? '<span style="color: red; margin-left: 4px; position: relative; bottom: 6px">*</span>' : ""}
  </div>`;

  const remark = useNormalizedRichText(st["person-remark"]);

  const isTaskWithoutNotification = st.channel === null && st.code === null;
  const hasCustomOptionsDetails =
    Array.isArray(st["custom-options"]?.details) &&
    st["custom-options"]?.details.length > 0;

  const address = st.address ? (
    href ? (
      <StyledLink sx={{ display: "block" }} href={href}>
        {st.address}
      </StyledLink>
    ) : (
      <Typography variant="body2">{st.address}</Typography>
    )
  ) : null;

  const text = (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box>
        <Typography
          component="div"
          variant="subtitle2"
          textOverflow="ellipsis"
          sx={{
            "* > p": { mt: 0 },
            color: st.status
              ? (theme) => theme.palette.grey[500]
              : (theme) => theme.palette.common.black[500],
            textDecoration: st.status ? "line-through" : "none",
          }}
          dangerouslySetInnerHTML={{
            __html: !isTaskWithoutNotification ? description : "",
          }}
        />
        <Typography variant="body2">{st.person}</Typography>
        {dataUrl && address ? (
          <HtmlTooltip
            title={<StQrCode dataUrl={dataUrl} smsBody={smsBody} />}
            placement="left"
            arrow
          >
            {address}
          </HtmlTooltip>
        ) : (
          address
        )}
        {remark ? (
          <LimitedHeightContent
            content={remark}
            width={"100%"}
            maxWidth={"280px"}
          />
        ) : null}
      </Box>
    </Box>
  );

  const manaualSmsAllowed =
    user?.["alarm-operator"]?.admin || user?.["alarm-operator"]?.["manual-sms"];

  const conditionalSetSubtaskStatus = () => {
    if (!hasCustomOptionsDetails) {
      setSubtaskStatus(st.id, true);
    }
  };

  useEffect(() => {
    const customResults = st["custom-results"];
    if (customResults && customResults.details) {
      setInitialValue(customResults.details);
    } else {
      setInitialValue("");
    }
  }, [st]);

  return (
    <Box px={1} sx={{ overflowX: "auto" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          pl: 1,
          pr: 1,
          pt: 2,
          pb: 2,
        }}
      >
        <TaskAltIcon
          color="primary"
          sx={{
            visibility: st.status ? "visible" : "hidden",
            mr: 2,
            ml: 0,
            fontSize: "22px",
          }}
        />
        <Box key={st.id} width="100%">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {text}
            {!readonly && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {protocol === "sms:" && !!st.address && manaualSmsAllowed && (
                  <StyledTooltip
                    title={formatMessage({ defaultMessage: "Manual SMS" })}
                  >
                    <StyledIconButton
                      onClick={() => {
                        setManualSmsModalOpen(true);
                      }}
                      sx={{
                        p: 0,
                      }}
                    >
                      <SmsIcon />
                    </StyledIconButton>
                  </StyledTooltip>
                )}

                {protocol === "mailto:" && !!st.address && (
                  <EmailButton
                    type="intervention"
                    initialValues={{ to: st.address }}
                    intervention={it}
                    customer={it?.customer}
                    sx={{
                      p: 0,
                    }}
                    templateContext={["customer", "intervention"]}
                    setSubtaskStatus={conditionalSetSubtaskStatus}
                  />
                )}

                {protocol === "tel:" && !!st.address && (
                  <StyledTooltip
                    title={formatMessage({ defaultMessage: "Call" })}
                  >
                    <StyledIconButton
                      component="a"
                      href={href}
                      sx={{
                        p: 0,
                      }}
                      onClick={() => {
                        if (!hasCustomOptionsDetails) {
                          setSubtaskStatus(st.id, true);
                        }
                      }}
                    >
                      <CallIcon />
                    </StyledIconButton>
                  </StyledTooltip>
                )}

                {!hasCustomOptionsDetails && (
                  <Box sx={{ mt: "auto" }}>
                    <StyledTooltip
                      title={
                        st.status
                          ? formatMessage({ defaultMessage: "Undo" })
                          : formatMessage({ defaultMessage: "Done" })
                      }
                    >
                      <StyledIconButton
                        onClick={() => setSubtaskStatus(st.id, !st.status)}
                        sx={{ p: 0 }}
                      >
                        {st.status ? (
                          <ReplayIcon
                            sx={{
                              color: (theme) => theme.palette.warning.main,
                            }}
                          />
                        ) : (
                          <SvgIcon
                            sx={{
                              color: (theme) => theme.palette.primary.main,
                            }}
                            viewBox="0 0 45 55"
                            component={InterventionsMenuIcon}
                          />
                        )}
                      </StyledIconButton>
                    </StyledTooltip>
                  </Box>
                )}
              </Box>
            )}
          </Box>

          {isTaskWithoutNotification && hasCustomOptionsDetails && (
            <Box>
              {!readonly ? (
                <CustomResultWidget
                  key={st.id + initialValue}
                  valueFromAPI={
                    (st["custom-options"]?.details as unknown as string[]) ?? []
                  }
                  initialValue={initialValue}
                  setCustomResults={setCustomResults}
                  st={st}
                  isTaskWithoutNotification={isTaskWithoutNotification}
                  description={description}
                  isLoading={isLoading}
                  isRequestError={isRequestError}
                />
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      "& p": { margin: 0 },
                      color: st.status
                        ? (theme) => theme.palette.grey[500]
                        : (theme) => theme.palette.common.black[500],
                      textDecoration: st.status ? "line-through" : "none",
                    }}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                  {initialValue && (
                    <Typography sx={{ fontSize: "0.875rem", mt: "12px" }}>
                      {initialValue}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          )}

          {!isTaskWithoutNotification &&
            hasCustomOptionsDetails &&
            (!readonly ? (
              <Box>
                <CustomResultWidget
                  key={st.id + initialValue}
                  valueFromAPI={
                    (st["custom-options"]?.details as unknown as string[]) ?? []
                  }
                  initialValue={initialValue}
                  setCustomResults={setCustomResults}
                  st={st}
                  isTaskWithoutNotification={isTaskWithoutNotification}
                  description={description}
                  isLoading={isLoading}
                  isRequestError={isRequestError}
                />
              </Box>
            ) : (
              initialValue && (
                <Typography sx={{ fontSize: "0.875rem", mt: 1.5 }}>
                  {initialValue}
                </Typography>
              )
            ))}

          {manualSmsModalOpen && !!st.address && (
            <ManualSmsModal
              intervention={it}
              initialValues={{ recipient: st.address, message: smsBody }}
              handleQueryError={() =>
                setSnackbarState({
                  message: formatMessage({
                    defaultMessage: "SMS sending request failed",
                  }),
                  error: true,
                })
              }
              handleQuerySuccess={() =>
                setSnackbarState({
                  message: formatMessage({
                    defaultMessage: "SMS sending request sent",
                  }),
                })
              }
              setSubtaskStatus={conditionalSetSubtaskStatus}
              onClose={() => setManualSmsModalOpen(false)}
            />
          )}
          <Snackbar onClose={snackbarOnClose} state={snackbarState} />
        </Box>
      </Box>
      {separator && <Divider />}
    </Box>
  );
};

const ReviewCard = ({
  readonly,
  interventionAllowed,
  isOpen,
  alertReview,
  refetchIntervention,
}: {
  readonly: boolean;
  interventionAllowed: boolean;
  isOpen: boolean;
  alertReview: ExtendedAlertReviewTask[];
  refetchIntervention: () => void;
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { data: cdec } = useCdec(alertReview[0].cdec.id);

  const cardId = alertReview[0].id;
  const isAllTaskDone = !alertReview.every((task) => task.details !== null);

  const [openTaskIndex, setOpenTaskIndex] = useState<number | null>(null);
  const [userToggled, setUserToggled] = useState<boolean>(false);

  const [isCardOpen, setIsCardOpen] = useState<boolean>(() => {
    const storedState = sessionStorage.getItem(`card-${cardId}`);
    return storedState ? JSON.parse(storedState) : isAllTaskDone;
  });

  const { mutate: updateAlertReviewTask } = useUpdate(
    "alarm/intervention-alert-review-tasks",
    {
      mutationKey: "interventionTaskUpdate",
    },
  );

  const handleCdecModalClose = () => {
    setOpenTaskIndex(null);
  };

  const handleAlert = (result: boolean, index: number, reason?: string) => {
    updateAlertReviewTask(
      {
        id: alertReview[index].id,
        result,
        ...(result === false && reason ? { details: { reason } } : {}),
      },
      {
        onSettled: () => {
          refetchIntervention();
        },
      },
    );
  };

  const getBorderColor =
    (alertReviews: ExtendedAlertReviewTask[]) => (theme: Theme) => {
      const allFalse = alertReviews.every((review) => review.result === false);
      if (allFalse) return theme.palette.divider;

      const hasNull = alertReviews.some((review) => review.result === null);
      if (hasNull) return theme.palette.primary.main;

      return theme.palette.divider;
    };

  useEffect(() => {
    sessionStorage.setItem(`card-${cardId}`, JSON.stringify(isCardOpen));
  }, [cardId, isCardOpen]);

  useEffect(() => {
    const allTasksCompleted = alertReview.every(
      (task) => task.details !== null,
    );

    if (allTasksCompleted && !userToggled) {
      setIsCardOpen(false);
    }
  }, [alertReview, userToggled]);

  const handleToggleCollapse = () => {
    setIsCardOpen((prev) => !prev);
    setUserToggled(true);
  };

  return (
    <Card
      sx={{
        mb: 1,
        boxShadow: 3,
        borderLeft: 3,
        borderRadius: 0,
        borderColor: getBorderColor(alertReview),
        p: 2,
      }}
    >
      <Box
        onClick={() => {
          if (!isAllTaskDone) {
            handleToggleCollapse();
          }
        }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          cursor: !isAllTaskDone ? "pointer" : "default",
        }}
      >
        <Box>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "0.9rem",
              color: cdec?.color,
              mb: 0.5,
            }}
          >
            {alertReview[0]?.cdec["localized-description"]}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <TimeIcon sx={{ mr: 1, fontSize: "18px", color: "#727272" }} />
            <Typography sx={{ fontSize: "0.8rem" }}>
              {getDateText(alertReview[0].cdec.arrived, intl, false, true)}
            </Typography>
          </Box>
        </Box>
        <ExpandLessIcon
          sx={{
            transition: "transform 0.3s ease",
            transform: isCardOpen ? "rotate(0deg)" : "rotate(-180deg)",
            display: !isAllTaskDone ? "block" : "none",
          }}
        />
      </Box>

      <Collapse in={isCardOpen}>
        <Divider sx={{ mt: 1 }}></Divider>

        <Box sx={{ mt: 2 }}>
          {alertReview.map((task, index) => {
            const autocompleteOptions = task["task-options"]
              ?.details as string[];

            return (
              <Box key={index}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <DescriptionIcon
                    sx={{ mr: 1, fontSize: "18px", color: "#727272" }}
                  />
                  <Typography sx={{ fontSize: "0.9rem" }}>
                    {alertReview.length > 1
                      ? `${index + 1}. ${formatMessage({
                          defaultMessage: "Task",
                        }).toLowerCase()}`
                      : formatMessage({
                          defaultMessage: "Task",
                        })}
                  </Typography>
                </Box>

                <Card
                  elevation={0}
                  sx={{
                    background: "#eeeeee",
                    borderRadius: "10px",
                    "& .MuiCardContent-root:last-child": {
                      pb: 1,
                    },
                  }}
                >
                  <CardContent
                    sx={{
                      p: 1,
                      borderRadius: "20px",
                    }}
                  >
                    <Typography
                      dangerouslySetInnerHTML={{ __html: task.description }}
                      sx={{ "& > p": { m: 0 }, fontSize: "0.85rem" }}
                    />
                  </CardContent>
                </Card>

                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    mt: 1.5,
                    mb: index < alertReview.length - 1 ? 2 : 0,
                  }}
                >
                  <Tooltip
                    placement="top"
                    arrow
                    title={
                      alertReview[index]?.details?.reason
                        ? `${formatMessage({ defaultMessage: "Review result" })}: ${alertReview[index]?.details?.reason}`
                        : ""
                    }
                  >
                    <span style={{ width: "100%" }}>
                      <Button
                        variant="contained"
                        size="small"
                        disabled={
                          task.result !== null ||
                          readonly ||
                          !interventionAllowed ||
                          !isOpen
                        }
                        fullWidth
                        startIcon={
                          <SvgIcon
                            viewBox="0 0 18 18"
                            component={AlertReviewTasksIcon}
                          />
                        }
                        onClick={() => setOpenTaskIndex(index)}
                        sx={{ borderRadius: "10px" }}
                      >
                        {formatMessage({ defaultMessage: "Review" })}
                      </Button>
                    </span>
                  </Tooltip>

                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<DoneIcon />}
                    onClick={() => handleAlert(true, index)}
                    disabled={readonly || !interventionAllowed || !isOpen}
                    sx={{
                      whiteSpace: "nowrap",
                      minWidth: "140px",
                      borderRadius: "10px",
                    }}
                  >
                    {formatMessage({ defaultMessage: "Intervention" })}
                  </Button>
                </Box>

                {openTaskIndex === index && (
                  <CdecModal
                    cdecID={task.cdec.id}
                    alertReview={{
                      isAlertReview: true,
                      alertReviewId: index,
                      reviewFieldsVisibility: true,
                      handleAlert,
                      autocompleteOptions,
                      alertReviewTaskDescription: task.description,
                    }}
                    onClose={handleCdecModalClose}
                  />
                )}
              </Box>
            );
          })}
        </Box>
      </Collapse>
    </Card>
  );
};

const RenderAlertReviewTasks = ({
  intervention,
  isOwn,
  interventionAllowed,
  isOpen,
  refetchIntervention,
}: {
  intervention?: Intervention;
  isOwn: boolean;
  interventionAllowed: boolean;
  isOpen: boolean;
  refetchIntervention: () => void;
}) => {
  const [openInterventionCloseDialog, setOpenInterventionCloseDialog] =
    useState(false);

  const groupedTasks = groupTasksByCdecId(
    (intervention?.["alert-review-tasks"] ||
      []) as unknown as ExtendedAlertReviewTask[],
  );

  const {
    params: { id },
  } = useRouteMatch<ExtractRouteParams<`${string}/:id`, string>>();

  const allTasksCompleted = Object.values(groupedTasks).every((tasks) =>
    tasks.every((task) => task.details !== null),
  );

  useEffect(() => {
    if (allTasksCompleted) {
      setOpenInterventionCloseDialog(true);
    }
  }, [allTasksCompleted]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {Object.entries(groupedTasks).map(([cdecId, tasks]) => (
        <ReviewCard
          key={cdecId}
          readonly={!isOwn}
          interventionAllowed={interventionAllowed}
          isOpen={isOpen}
          alertReview={tasks}
          refetchIntervention={refetchIntervention}
        />
      ))}

      {allTasksCompleted && isOwn && interventionAllowed && isOpen && (
        <InterventionCloseDialog
          open={openInterventionCloseDialog}
          onClose={() => setOpenInterventionCloseDialog(false)}
          id={id}
          handleQueryError={(error) => console.error(error)}
          intervention={intervention}
          refetch={refetchIntervention}
        />
      )}
    </Box>
  );
};

const TaskListSubheader = styled("h3")(({ theme }) => ({
  position: "sticky",
  top: 0,
  zIndex: 1,

  boxSizing: "border-box",
  lineHeight: theme.spacing(6),

  color: theme.palette.text.secondary,
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.pxToRem(14),
  backgroundColor: theme.palette.background.paper,

  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: 0,
  paddingBottom: 0,
  margin: 0,
}));

export const SubtasksWidget = ({
  intervention,
  interventionAllowed,
  isOwn,
  refetchIntervention,
  sx,
}: {
  intervention?: Intervention;
  interventionAllowed: boolean;
  isOwn: boolean;
  refetchIntervention: () => Promise<any>;
  sx?: SxProps;
}) => {
  const { formatMessage } = useIntl();
  const [unackCdecs] = useAcknowledge();

  const {
    mutate: updateSubtask,
    isLoading,
    isError,
  } = useUpdate<Subtask>("alarm/subtasks", {
    mutationKey: "interventionTaskUpdate",
  });
  const isOpen = intervention?.["close-time"] === null;

  const { data: company } = useGetOne<CompanyInfo>("alarm/company-info", "1");

  const [error, setError] = useState<JsonapiError | null>(null);
  const handleSnackbarClose = () => {
    setError(null);
  };
  const handleQueryError = (error: JsonapiError) => {
    setError(error);
  };

  const setSubtaskStatus = (id: string, status: boolean) => {
    updateSubtask(
      { id, status },
      {
        onSettled: () => {
          refetchIntervention();
        },
        onError: (error) => {
          handleQueryError(error);
        },
      },
    );
  };

  const setSubtaskCustomResults = (id: string, customResult: string) => {
    const customResultValue =
      customResult.length > 0 ? { details: customResult } : null;
    updateSubtask(
      {
        id,
        "custom-results": customResultValue,
        status: customResultValue ? true : false,
      },
      {
        onSettled: () => {
          refetchIntervention();
        },
        onError: (error) => {
          handleQueryError(error);
        },
      },
    );
  };

  const toSimpleList = (
    { id, subtasks, "action-group": ag }: TaskGroup,
    j: number,
    arr: TaskGroup[],
  ) => (
    <Box key={id} m={0}>
      <TaskListSubheader
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {ag?.description ??
          formatMessage({
            defaultMessage: "Deleted task group",
            description: "Deleted task group message",
          })}
      </TaskListSubheader>
      {subtasks?.map((element, i) => (
        <STListItem
          key={element.id}
          it={intervention}
          st={element}
          ag={ag}
          cmp={company ?? null}
          setSubtaskStatus={setSubtaskStatus}
          setCustomResults={setSubtaskCustomResults}
          readonly={!interventionAllowed || !isOwn || !isOpen}
          separator={i !== subtasks.length - 1 || j !== arr.length - 1}
          isLoading={isLoading}
          sx={{ mt: i === 0 ? 0 : 1 }}
          isRequestError={isError}
        />
      ))}
    </Box>
  );

  const countRequiredTasks = (
    taskGroups: TaskGroup[],
  ): {
    taskListsWithRequired: number;
    completedRequiredTasks: number;
  } => {
    let taskListsWithRequired = 0;
    let completedRequiredTasks = 0;

    taskGroups.forEach((taskGroup) => {
      const requiredTasks = (taskGroup.subtasks || []).filter(
        (subtask: Subtask) => subtask["custom-options"]?.required ?? false,
      );

      if (requiredTasks.length > 0) {
        taskListsWithRequired += 1;

        const completedTasksInList = requiredTasks.filter(
          (subtask: Subtask) => subtask.status,
        ).length;

        completedRequiredTasks += completedTasksInList > 0 ? 1 : 0;
      }
    });

    return { taskListsWithRequired, completedRequiredTasks };
  };

  const taskListSummaryData = countRequiredTasks(
    intervention?.["task-groups"] || [],
  );

  const remainingReviewTaskCount = (
    alertReviewTasks: ExtendedAlertReviewTask[] | null | undefined,
  ): {
    completedReviewTaskCount: number;
    allReviewTaskCount: number;
  } => {
    if (!alertReviewTasks || !Array.isArray(alertReviewTasks)) {
      return { completedReviewTaskCount: 0, allReviewTaskCount: 0 };
    }

    let completedReviewTaskCount = 0;
    let allReviewTaskCount = alertReviewTasks.length;

    alertReviewTasks.forEach((alertReviewTask) => {
      if (alertReviewTask.result !== null) {
        completedReviewTaskCount++;
      }
    });

    return { completedReviewTaskCount, allReviewTaskCount };
  };

  const remainingReviewTaskCountSummary = remainingReviewTaskCount(
    intervention?.[
      "alert-review-tasks"
    ] as unknown as ExtendedAlertReviewTask[],
  );

  const title =
    intervention?.phase === "execute-tasks"
      ? formatMessage({
          defaultMessage: "Tasks",
          description: "Intervention page Tasks widget title",
        })
      : formatMessage({ defaultMessage: "Alarm review" });

  const renderTaskGroups = () =>
    intervention?.["task-groups"]?.length ? (
      <Box
        display="flex"
        flexDirection="column"
        sx={{ overflowX: "hidden", overflowY: "auto" }}
      >
        {intervention["task-groups"].map(toSimpleList)}
      </Box>
    ) : null;

  return (
    <>
      <Widget
        title={title}
        postfix={
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            {!isOpen && (
              <Typography>
                {formatMessage({ defaultMessage: "Closed intervention" })}
              </Typography>
            )}
            {unackCdecs.length > 0 && (
              <BootstrapTooltip
                backgroundColor="info.main"
                title={formatMessage({
                  defaultMessage: "Unacknowledged events !",
                })}
              >
                <PriorityHighIcon
                  fontSize="small"
                  sx={{ color: "common.white" }}
                />
              </BootstrapTooltip>
            )}
            {taskListSummaryData.taskListsWithRequired !== 0 &&
              intervention?.phase === "execute-tasks" && (
                <Tooltip
                  title={formatMessage(
                    {
                      defaultMessage:
                        "{requiredTaskCount} out of {completedTask} required tasks are done. (1 required task per list)",
                    },
                    {
                      completedTask: taskListSummaryData.completedRequiredTasks,
                      requiredTaskCount:
                        taskListSummaryData.taskListsWithRequired,
                    },
                  )}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography>
                      {taskListSummaryData.completedRequiredTasks}/
                    </Typography>
                    <Typography>
                      {taskListSummaryData.taskListsWithRequired}
                    </Typography>
                  </Box>
                </Tooltip>
              )}
            {intervention?.phase === "review-alerts" && (
              <Tooltip
                title={formatMessage(
                  {
                    defaultMessage:
                      "{completedReviewTasks} out of {allReviewTasks} review tasks are completed",
                  },
                  {
                    completedReviewTasks:
                      remainingReviewTaskCountSummary.completedReviewTaskCount,
                    allReviewTasks:
                      remainingReviewTaskCountSummary.allReviewTaskCount,
                  },
                )}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography>
                    {remainingReviewTaskCountSummary.completedReviewTaskCount}/
                  </Typography>
                  <Typography>
                    {remainingReviewTaskCountSummary.allReviewTaskCount}
                  </Typography>
                </Box>
              </Tooltip>
            )}
          </Box>
        }
        placeholderMessage={formatMessage({
          defaultMessage: "No tasks",
          description: "Tasks widget No tasks message",
        })}
        sx={sx}
      >
        <Box
          sx={{
            overflow: "auto",
          }}
        >
          {intervention?.phase === "execute-tasks" ? (
            renderTaskGroups()
          ) : (
            <RenderAlertReviewTasks
              intervention={intervention}
              isOwn={isOwn}
              interventionAllowed={interventionAllowed}
              isOpen={isOpen}
              refetchIntervention={refetchIntervention}
            />
          )}
        </Box>
      </Widget>
      <JsonApiErrorSnackbar error={error} onClose={handleSnackbarClose} />
    </>
  );
};

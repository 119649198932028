import { Button, TextField } from "@mb-pro-ui/components";
import { useApi } from "@mb-pro-ui/utils";
import { JsonapiError } from "@mb-pro-ui/utils/jsonapi/types";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
} from "@mui/material";
import { ReactElement, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getErrorMessage } from "../../../../hooks/useErrorHandler";

type ManualEventModalProps = {
  open: boolean;
  onClose(): void;
  account?: string;
};

type ManualEventData = {
  customer: string;
  message: string;
};

export const ManualEventModal = ({
  open,
  onClose,
  account,
}: ManualEventModalProps) => {
  const { formatMessage } = useIntl();
  const api = useApi();
  const [messageValidation, setMessageValidation] = useState<string>("\u00a0");
  const [customer, setCustomer] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [snackbarMessage, setSnackbarMessage] = useState<
    string | ReactElement | null
  >();
  const firstAttemptRef = useRef(true);

  const create = (manualEventData: ManualEventData) => {
    return api<void>("/actions/alarm/manual-event", {
      method: "post",
      headers: {
        Accept: "application/vnd.api+json, application/json",
        "Content-Type": "application/vnd.api+json",
      },
      body: JSON.stringify(manualEventData),
    });
  };

  const { mutate: createManualEvent } = useMutation<
    void,
    JsonapiError,
    ManualEventData
  >(create);

  const handleQueryError = (error: JsonapiError) => {
    setSnackbarMessage(getErrorMessage(error));
  };

  const handleQuerySuccess = () => {
    setSnackbarMessage(
      formatMessage({
        defaultMessage: "Event successfully sent",
        description: "Kézi eseményküldés sikeres snackbar üzenet",
      }),
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    firstAttemptRef.current = false;
    const messageValid = isMessageValid();

    if (!messageValid) {
      return;
    }

    createManualEvent(
      { customer: account ?? customer, message },
      { onError: handleQueryError, onSuccess: handleQuerySuccess },
    );
    handleClose();
  };

  const handleAccountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomer(e.target.value);
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const handleMessageKeyUp = () => {
    isMessageValid();
  };

  const isMessageValid = () => {
    if (message.trim() === "") {
      const message = formatMessage({
        defaultMessage: "Custom message, required field.",
        description: "Manual event custom message required",
      });
      setMessageValidation(message);
      return false;
    }
    setMessageValidation("\u00a0");
    return true;
  };

  const handleClose = () => {
    firstAttemptRef.current = true;
    setMessageValidation("\u00a0");
    setCustomer("");
    setMessage("");
    onClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarMessage(null);
  };

  const isFirstAttempt = firstAttemptRef.current;

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableScrollLock>
        <DialogTitle>
          <FormattedMessage defaultMessage="Manual event registration" />
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent
            sx={{ paddingTop: (theme) => theme.spacing(3), width: "400px" }}
          >
            {!account && (
              <TextField
                autoFocus
                margin="dense"
                id="account"
                label={formatMessage({
                  defaultMessage: "Customer identifier",
                  description: "Kézi eseményküldés ügyfél azonosító",
                })}
                type="text"
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                onChange={handleAccountChange}
                value={customer}
              />
            )}
            <TextField
              autoFocus={!!account}
              margin="dense"
              id="message"
              label={formatMessage({
                defaultMessage: "Event message",
                description: "Kézi esemény szövege",
              })}
              type="text"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              onKeyUp={isFirstAttempt ? undefined : handleMessageKeyUp}
              onChange={handleMessageChange}
              value={message}
              error={messageValidation !== "\u00a0"}
              helperText={messageValidation}
            />
          </DialogContent>
          <DialogActions>
            <Button mode="secondary" onClick={handleClose}>
              <FormattedMessage
                defaultMessage="Cancel"
                description="Manual event send cancel button"
              />
            </Button>
            <Button
              type="submit"
              disabled={(!customer && !account) || !message}
            >
              <FormattedMessage
                defaultMessage="Send"
                description="Kézi eseményküldés küldés gomb"
              />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={!!snackbarMessage}
        autoHideDuration={5000}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
        action={
          <>
            <IconButton
              size="small"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default ManualEventModal;
